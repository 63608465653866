html {
    height: 100%;
    overflow: hidden;
}
body {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}
#root {
    min-height: 560px;
    min-width: 350px;
}
#root {
    width: 100vw;
    height: 100vh;
    overflow: hidden scroll;
}
.swal2-container {
    z-index: 10000 !important;
}
